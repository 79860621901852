<template>
  <div>
    <div>
      <button
        v-b-modal.modal-primary
        variant="primary"
        class="btn btn-primary mb-2"
      >
        Ajouter une fonction
      </button>
    </div>
    <b-modal
      ref="my-modal"
      show="resetModal"
      id="modal-primary"
      centered
      modal-class="modal-primary"
      title="Ajouter une fonction"
      hide-footer
    >
      <validation-observer ref="fonctionForm">
        <b-form @submit.stop.prevent="fonctionForm">
          <b-form-group>
            <b-form-input
              placeholder="Nom du fonction"
              invalid-feedback="re"
              v-model="fonction.name"
              type="text"
            />
          </b-form-group>
          <b-form-group>
            <b-form-textarea
              v-model="fonction.description"
              placeholder="Description du fonction"
              rows="4"
            />
          </b-form-group>
          <div v-if="errorMessage" class="alert alert-danger">
            <span> {{ errorMessage }}</span>
          </div>
          <b-form-group>
            <b-button @click.prevent="addFonctions">Ajouter</b-button>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-card>
      <b-tabs>
        <!-- fonctions tab -->
        <b-tab>
          <template #title>
            <feather-icon icon="UsersIcon" />
            <span>Fonctions du service</span>
          </template>
          <b-card>
            <div class="d-flex justify-content-between flex-wrap">
              <b-form-group
                label-cols-sm="10"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInput"
                class="mb-0 ml-auto"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Rechercher..."
                  />
                  <b-input-group-append>
                    <b-button @click="filter = ''" class="btn btn-secondary">
                      Effacer
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>

            <b-table
              fixed
              :items="fonctions"
              :empty-text="'Aucune fonction disponible'"
              :fields="fonctionItems"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              show-empty
              @filtered="onFiltered"
            >
              <template #cell(actions)="row">
                <b-row class="text-right">
                  <b-col class="col-md-3">
                    <b-button @click="row.item.id" class="btn btn-sm">
                      <feather-icon icon="EditIcon" size="20" />
                    </b-button>
                  </b-col>

                  <b-col class="col-md-2">
                    <b-button
                      @click="row.item.id"
                      class="btn btn-sm btn-danger"
                    >
                      <feather-icon icon="TrashIcon" size="20" />
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </b-table>

            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- page length -->
              <b-form-group
                label="Affichage par page"
                label-cols="8"
                label-align="left"
                label-size="sm"
                label-for="sortBySelect"
                class="text-nowrap mb-md-0 mr-1"
              >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  inline
                  :options="pageOptions"
                />
              </b-form-group>

              <!-- pagination -->
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-tab>

        <!-- Collaborators tab -->
        <b-tab>
          <template #title>
            <feather-icon icon="UsersIcon" />
            <span>Collaborateurs du service</span>
          </template>
          <b-card>
            <div class="d-flex justify-content-between flex-wrap">
              <b-form-group
                label-cols-sm="10"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInput"
                class="mb-0 ml-auto"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Rechercher..."
                  />
                  <b-input-group-append>
                    <b-button @click="filter = ''" class="btn btn-secondary">
                      Effacer
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>

            <b-table
              no-border-collapse
              hover
              responsive
              class="position-relative"
              :per-page="perPage"
              :current-page="currentPage"
              :items="employees"
              :fields="collaboratorItems"
              show-empty
              empty-text="Pas de collaborateur disponible pour ce service"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
            </b-table>

            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- page length -->
              <b-form-group
                label="Affichage par page"
                label-cols="8"
                label-align="left"
                label-size="sm"
                label-for="sortBySelect"
                class="text-nowrap mb-md-0 mr-1"
              >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  inline
                  :options="pageOptions"
                />
              </b-form-group>

              <!-- pagination -->
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import KyInput from "@/@core/layouts/components/ky-input.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BCard
} from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    ValidationProvider,
    ValidationObserver,
    KyInput,
    ToastificationContent,
  },
  data() {
    return {
      rows: 0,
      name: "",
      description: "",
      errors: "",
      errorMessage: "",
      error: "",
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fonctionItems: [
        { key: "name", label: "Nom" },
        { key: "description", label: "Description" },
        { key: "actions", label: "" },
      ],

      collaboratorItems: [
        { key: "firstname", label: "Nom" },
        { key: "lastname", label: "Prénom (s)" },
        { key: "email", label: "Email", thStyle: { width: "5px" } },
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },

    ...mapState("enterprise", [
      "fonctions",
      "fonction",
      "service",
      "employees",
    ]),
  },
  mounted() {
    this.fetchFonctions(this.$route.params.id);
    this.getEmployees();
  },
  methods: {
    async addFonctions() {
      const valid = await this.$refs.fonctionForm.validate();
      if (!valid) return;
      this.loading = true;
      this.fonction.service_id = this.$route.params.id;
      this.addFonction(this.$formData(this.fonction))
        .then((r) => {
          this.loading = true;
          this.fonction.name = "";
          this.fonction.description = "";
          this.resetModal = false;
          this.$refs["my-modal"].hide();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Fonction ajoutée avec succès",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.errorMessage = error.message;
          this.popover_disabled = false;
          this.$refs.form_popover.$emit("open");
        });
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(fonctions) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = fonctions.length;
      this.currentPage = 1;
    },

    ...mapActions("enterprise", [
      "addFonction",
      "fetchFonctions",
      "getEmployees",
    ]),
    ...mapActions("enterprise", ["showService"]),
  },
};
</script>

